import { FunctionComponent } from 'react'
import { FeaturePageTemplate, TFeatureItem } from './_featurePageTemplate'
import hebrewImg from './imgs/ner/hebrew.png'
import preAnnoImg from './imgs/ner/suggest.gif'
import qualityImg from './imgs/ner/quality.png'
import anyEntityImg from './imgs/ner/anyEntity.png'
const items: TFeatureItem[] = [
  {
    title: 'Double Your Productivity With Pre-Annotations',
    description:
      'Increase annotator throughput with pre-annotations. You can bring your own models and dictionaries or let LightTag learn from you. Either way, your team will focus on adding value, letting the machine do everything it can for you.',
    img: preAnnoImg,
  },
  {
    title: 'Customize Your Entities',
    description:
      "With LightTag you can define any set of entities you'd like your team to annotate",
    img: anyEntityImg,
  },
  {
    title: 'Annotate Anything In Any Language',
    description:
      'LightTag supports any language and any document format,. Our customers annotate legal contracts, mandarin tweets and Hebrew medical records.',
    img: hebrewImg,
  },

  {
    title: 'Built In Quality Controls',
    description:
      'LightTag detects likely errors and warns your annotators, reducing annotation mistakes and increasing your productivity.',
    img: qualityImg,
  },
]
const NamedEntityRecognition: FunctionComponent = () => {
  return (
    <FeaturePageTemplate
      header="Named Entity Recognition Annotation"
      subheader={'The Easy Way To Label Data For NER NLP Tasks'}
      featureItems={items}
      googleDescription={
        'Create highly accurate, fine-grained named entity annotations from  any source text.'
      }
      googleKeywords={[
        'NER',
        'Named Entity Recognition',
        'Named Entity Recognition annotation',
        'Named Entity Recognition labeling',
      ]}
    />
  )
}

export default NamedEntityRecognition
